Sim.require.amd.registerRaw("/app/components/Popover/Popover.js", ["/app/components/Popover/PopoverLazyContent.js"],  (PopoverLazyContent) => {

	/**
	 * Events:
	 * - `shown`
	 * - `hidden`
	 * - `contentReady` after proper content is fully loaded (lazy or not) and popover is shown
	 * - `contentUpdate` when content is updating with any means at any time.
	 * 	Can read/modify eventData (second argument in handler):
	 * 	<code>
	 * 		{
	 * 			titleHtml: (string|undefined), // can be modified
	 * 			titleHtml: (string|undefined), // can be modified
	 * 			persistent: boolean, // can be modified
	 * 			updateSourceType: string, // show|loading|lazy|error|manual
	 * 		}
	 * 	</code>
	 */
	class Popover
	{

		static init($el)
		{
			return $el.data('popover') || new Popover($el);
		}

		constructor(el)
		{
			this.toggle = $(el);
			if (this.toggle.length !== 1) throw new Error;

			this.onResize = Sim.onResize.create(this.toggle, () => this.redraw());

			this.toggle
				.on('shown', () => this.onPopoverShown())
				.on('hidden', () => this.onPopoverHidden())
			;

			const options = {
				animation: true,
				container: false,
				placement: 'right',
				trigger: 'hover',
				lazy: null,
				...this.toggle.data(),
				template:
					'<div class="popover">' +
						'<div class="arrow"></div>' +
						'<div class="popover-inner">' +
							'<button type="button" style="display: none;"></button>' + // workaround when inside label:not([for])
							'<button type="button" class="a-closeButton" data-dismiss="popover"></button>' +
							'<h3 class="popover-title"></h3>' +
							'<div class="popover-content"></div>' +
						'</div>' +
					'</div>'
				,
			};
			this.content = new PopoverLazyContent(options, (...args) => this.updateHtmlContent(...args));
			for (const key of ['html', 'title', 'title-text', 'title-html', 'content', 'content-text', 'content-html', 'lazy'])
			{
				const ccKey = key.replace(/-([a-z])/gu, ($0, $1) => $1.toUpperCase());
				delete options[ccKey];
				this.toggle.removeAttr(`data-${key}`).data(ccKey, undefined);
			}
			this.options = options;
			this.toggle.data('popover', this);

			if (this.options.trigger === 'hover' || this.options.trigger === 'click')
			{
				this.toggle
					.on('mouseenter', (e) => this.onToggleMouseEnter(e))
					.on('mouseleave', (e) => this.onToggleMouseLeave(e))
				;
				if (this.options.trigger === 'click')
				{
					this.toggle.on('click', (e) => this.onToggleClick(e));
				}
				else if (this.options.trigger === 'hover' && Sim.filterHoverElements(this.toggle).length)
				{
					this.onToggleMouseEnter();
				}
			}
			else if (this.options.trigger === 'hover-trigger')
			{
				const $trigger = this.toggle.find('.popover-toggle-hover-trigger')
					.on('mouseenter', (e) => this.onToggleMouseEnter(e))
					.on('mouseleave', (e) => this.onToggleMouseLeave(e))
				;
				if (Sim.filterHoverElements($trigger).length)
				{
					this.onToggleMouseEnter();
				}
			}
			else if (this.options.trigger !== 'manual')
			{
				throw new Error(this.options.trigger);
			}
		}

		getPopoverTip()
		{
			if (this.$tip === undefined)
			{
				const $tip = $(this.options.template);
				$tip.on('click', '[data-dismiss="popover"]', (e) => {
					this.hide();
					return false;
				});
				$tip.on('click', '[data-reload-popover-content]', () => {
					this.content.startLoading(true, true);
					return false;
				});
				if (this.options.trigger === 'hover' || this.options.trigger === 'hover-trigger')
				{
					$tip
						.on('mouseenter', (e) => this.onPopoverMouseEnter(e))
						.on('mouseleave', (e) => this.onPopoverMouseLeave(e))
					;
				}
				else if (this.options.trigger === 'click')
				{
					this.onEscapePress = Sim.onEscapePress(() => this.hide());
					$(document).on('click mousedown mouseup focusin', (e) => this.onDocumentInteract(e));
				}
				$tip.attr('style', this.options.tipStyle);
				if (this.options.contentStyle !== undefined)
				{
					$tip.find('> .popover-inner > .popover-content').attr('style', this.options.contentStyle);
				}
				this.$tip = $tip;
			}
			return this.$tip;
		}

		isShown()
		{
			return this.getPopoverTip().hasClass('in');
		}

		isContentReady()
		{
			return this.isShown() && !this.content.lazy;
		}

		show()
		{
			this.updateHtmlContent({
				title: this.content.getTitleHtml(),
				content: this.content.getContentHtml(),
				persistent: false,
				redraw: true,
				updateSourceType: this.content.lazy ? 'loading' : 'show',
			});
			if (this.options.trigger !== 'hover' && this.options.trigger !== 'hover-trigger')
			{
				this.toggle.addClass('locked');
			}
			this.toggle.trigger('shown');
			if (!this.content.lazy)
			{
				this.toggle.trigger('contentReady');
			}
		}

		redraw()
		{
			const $tip = this.getPopoverTip();
			const animate = (this.options.animation && !this.isShown());
			$tip.removeClass('fade top bottom left right in');
			if (animate)
			{
				$tip.addClass('fade');
			}
			if (this.options.container && !$tip.parent().is(this.options.container))
			{
				$tip.appendTo(this.options.container);
			}
			else if (!this.options.container && !$tip.prev().is(this.toggle))
			{
				$tip.insertAfter(this.toggle);
			}
			this.applyPopoverPlacement();
		}

		hide()
		{
			const $tip = this.getPopoverTip();
			$tip.removeClass('in');
			$tip.detach();
			this.toggle.trigger('hidden');
		}

		applyPopoverPlacement()
		{
			const $tip = this.getPopoverTip();

			let {placement} = this.options;
			if (Sim.config.isRTL && !Sim.config.isRTLTestMode)
			{
				placement = {'right': 'left', 'left': 'right'}[placement] || placement;
			}

			$tip.css({top: '', left: '', display: 'none'});

			const docEl = (placement === 'bottom' || placement === 'top') ? this.getBoundingElement() : document.documentElement;
			const positiveDocumentWidth = Sim.config.isRTL ? docEl.clientWidth : docEl.scrollWidth;
			const negativeDocumentWidth = Sim.config.isRTL ? docEl.clientWidth - docEl.scrollWidth : 0;
			$tip.css('display', 'block');

			const $arrow = $tip.find('> .arrow');
			const replaceArrow = (percent, cssProperty) => {
				$arrow.css(cssProperty, percent === null ? '' : `${Math.min(Math.max(percent, 5), 95)}%`);
			};

			let margin = this.options.placementMargin;
			let def;
			if (margin !== undefined)
			{
				if (placement === 'top' || placement === 'bottom')
				{
					const max = ($tip[0].offsetWidth / 2) - ($arrow[0].offsetWidth / 2) - 3; // 3 = border radius
					margin = `${Math.max(Math.min(parseFloat(margin), max), -max)}px`;
				}
				def = {
					'left_right': {
						tip: ['margin-top', `+=${margin}`],
						arrow: ['margin-top', `-=${margin}`],
					},
					'top_bottom': {
						tip: ['margin-left', (Sim.config.isRTL ? '-=' : '+=') + margin],
						arrow: [Sim.config.isRTL ? 'margin-right' : 'margin-left', (Sim.config.isRTLTestMode ? '+=' : '-=') + margin],
					},
				}[(placement === 'left' || placement === 'right') ? 'left_right' : 'top_bottom'];
				$tip.css(def.tip[0], '');
				$arrow.css(def.arrow[0], '');
			}

			const width = $tip[0].offsetWidth;
			const height = $tip[0].offsetHeight;

			const offset = (() => {
				const pos = {..._.extend({}, this.toggle[0].getBoundingClientRect()), ...this.toggle.offset()};
				if (placement === 'bottom')
				{
					return {
						top: pos.top + pos.height,
						left: pos.left + (pos.width / 2) - (width / 2),
					};
				}
				else if (placement === 'top')
				{
					return {
						top: pos.top - height,
						left: pos.left + (pos.width / 2) - (width / 2),
					};
				}
				else if (placement === 'left')
				{
					return {
						top: pos.top + (pos.height / 2) - (height / 2),
						left: pos.left - width,
					};
				}
				else if (placement === 'right')
				{
					return {
						top: pos.top + (pos.height / 2) - (height / 2),
						left: pos.left + pos.width,
					};
				}
				throw new Error;
			})();

			// converts jquery offset calc `( options.left - curOffset.left ) + curLeft` into `( curOffset.left - options.left ) + curLeft`
			const fixTipOffset = (v) => ((Sim.config.isRTLTestMode && 'left' in v) ? {...v, left: (2 * $tip.offset().left) - v.left} : v);
			$tip.offset(fixTipOffset(offset)).addClass(placement).addClass('in');

			if (margin !== undefined)
			{
				$tip.css(def.tip[0], def.tip[1]);
				$arrow.css(def.arrow[0], def.arrow[1]);
			}

			const actualHeight = $tip[0].offsetHeight;
			if (placement === 'bottom' || placement === 'top')
			{
				const bounding = {..._.extend({}, docEl.getBoundingClientRect()), ...$(docEl).offset()};
				let actualWidth = $tip[0].offsetWidth;
				let delta = 0;
				const replace = {};
				if (placement === 'top' && actualHeight !== height)
				{
					replace.top = offset.top + height - actualHeight;
				}
				if ((offset.left - bounding.left) < negativeDocumentWidth)
				{
					delta = (offset.left - bounding.left - negativeDocumentWidth) * -2;
					replace.left = negativeDocumentWidth + bounding.left;
				}
				else if ((offset.left - bounding.left + actualWidth) > positiveDocumentWidth && positiveDocumentWidth > actualWidth)
				{
					delta = ((offset.left - bounding.left + actualWidth) - positiveDocumentWidth) * -2;
					replace.left = positiveDocumentWidth + bounding.left - actualWidth;
				}
				else if ((offset.left - bounding.left + actualWidth) > positiveDocumentWidth && positiveDocumentWidth <= actualWidth)
				{
					const limit = actualWidth * (1 - (positiveDocumentWidth / actualWidth * 100 / 50));
					delta = Math.max((offset.left - bounding.left) * -2, limit);
					replace.left = bounding.left;
				}
				if ('left' in replace || 'top' in replace)
				{
					$tip.offset(fixTipOffset(replace));
					actualWidth = $tip[0].offsetWidth;
				}
				replaceArrow(
					actualWidth ? 50 * (1 - (((delta * (Sim.config.isRTL ? -1 : 1)) - width + actualWidth) / actualWidth)) : null,
					Sim.config.isRTL ? 'right' : 'left'
				);
			}
			else
			{
				replaceArrow(
					actualHeight ? 50 * (1 - ((actualHeight - height) / actualHeight)) : null,
					'top',
				);
			}
		}

		getBoundingElement()
		{
			const reDisplayContents = /^\s*(contents)\s*$/iu;
			const reContainPaint = /(strict|content|paint)/iu;
			const rePositionAbsolute = /^\s*(absolute|fixed)\s*$/iu;
			const rePositionStatic = /^\s*(static)\s*$/iu;
			const reVisible = /^\s*(visible\s*){1,2}$/iu;
			const reNone = /^\s*(auto|none|undefined)\s*$/iu;
			let isPositionNonStatic = false;
			for (const el of this.getPopoverTip().parents())
			{
				const $el = $(el);
				const force = $el.attr('data-popover-bounding-element');
				if (force === 'true')
				{
					return el;
				}
				if (force === 'false')
				{
					continue;
				}
				if (reDisplayContents.test($el.css('display')))
				{
					continue; // element does not really exist for styling
				}
				const position = $el.css('position');
				if (
					reContainPaint.test($el.css('contain')) ||
					(rePositionAbsolute.test(position) && !reNone.test($el.css('clip'))) ||
					!reNone.test($el.css('clip-path'))
				)
				{
					return el; // similar effect as overflow hidden
				}
				if (!rePositionStatic.test(position))
				{
					isPositionNonStatic = true;
				}
				if (isPositionNonStatic && !reVisible.test($el.css('overflow')))
				{
					return el;
				}
			}
			return document.documentElement;
		}

		onPopoverShown()
		{
			this.onResize.on();
			this.onEscapePress && this.onEscapePress.on();
			this.toggle.addClass('active');
			$('.popover-toggle.active')
				.not(this.toggle)
				.not(this.toggle.parents('.popover').prev())
				.each((i, el) => {
					const obj = $(el).data('popover');
					if (obj) // is init
					{
						obj.hide();
					}
				})
			;
			this.content.startLoading(true);
		}

		onPopoverHidden()
		{
			this.onResize.off();
			this.onEscapePress && this.onEscapePress.off();
			this.toggle.removeClass('active');
			this.content.stopLoading();
		}

		triggerDelayedMouseAction(event, action, slow = false)
		{
			this.triggerTimer && clearTimeout(this.triggerTimer);
			this.triggerTimer = undefined;
			const isActive = this.toggle.hasClass('active');
			this.toggle.removeClass('locked');
			if (action === 'show' && !isActive)
			{
				this.triggerTimer = setTimeout(() => this.show(), 200);
			}
			else if (action === 'hide' && isActive && !Sim.filterHoverElements(this.getPopoverTip()).length)
			{
				const lock = (event.shiftKey || event.ctrlKey);
				if (!lock)
				{
					this.triggerTimer = setTimeout(() => this.hide(), slow ? 400 : 200);
				}
				else
				{
					this.toggle.addClass('locked');
				}
			}
		}

		onToggleMouseLeave(e)
		{
			if (this.options.trigger === 'hover' || this.options.trigger === 'hover-trigger')
			{
				const isAutohide = this.toggle.hasClass('popover-autohide');
				this.triggerDelayedMouseAction(e, 'hide', !isAutohide);
			}
			const isActive = this.toggle.hasClass('active');
			if (!isActive)
			{
				this.content.stopLoading();
			}
		}

		onToggleMouseEnter(e)
		{
			if (this.options.trigger === 'hover' || this.options.trigger === 'hover-trigger')
			{
				this.triggerDelayedMouseAction(e, 'show');
			}
			this.content.startLoading();
		}

		onPopoverMouseLeave(e)
		{
			this.triggerDelayedMouseAction(e, 'hide');
		}

		onPopoverMouseEnter(e)
		{
			this.triggerDelayedMouseAction(e);
		}

		onToggleClick(e)
		{
			const isActive = this.toggle.hasClass('active');
			if (!isActive)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
			e.preventDefault();
		}

		onDocumentInteract(e)
		{
			if (
				this.toggle.hasClass('active') &&
				(!e.isTrigger || e.type === 'focusin') && // only user clicks (jquery simulates focusin so isTrigger is set)
				$.contains(document, e.target) // only real elements
			)
			{
				const tip = this.getPopoverTip();
				const isInside = (
					tip[0] === e.target ||
					$.contains(tip[0], e.target) ||
					this.toggle[0] === e.target ||
					$.contains(this.toggle[0], e.target)
				);
				if ((e.type === 'mousedown' || e.type === 'mouseup') && isInside)
				{
					this.preventDocumentInteract = true;
				}
				else if (e.type === 'click' || e.type === 'focusin')
				{
					if (!isInside && !this.preventDocumentInteract)
					{
						this.hide();
					}
					this.preventDocumentInteract = false;
				}
			}
		}

		updateHtmlContent({title, content, persistent = false, redraw = false, updateSourceType})
		{
			const eventData = {titleHtml: title, contentHtml: content, persistent, updateSourceType};
			this.toggle.trigger('contentUpdate', eventData);
			const {titleHtml, contentHtml, persistent: isPersistent} = eventData;
			const $tip = this.getPopoverTip();
			if (titleHtml !== undefined)
			{
				const el = $tip.find('> .popover-inner > .popover-title').html(titleHtml);
				Sim.init(el);
				isPersistent && (this.content.titleHtml = titleHtml);
			}
			if (contentHtml !== undefined)
			{
				const el = $tip.find('> .popover-inner > .popover-content').html(contentHtml);
				Sim.init(el);
				isPersistent && (this.content.contentHtml = contentHtml);
			}
			if (redraw || this.isShown())
			{
				this.redraw();
			}
			if (updateSourceType === 'lazy' && this.isShown())
			{
				this.toggle.trigger('contentReady');
			}
		}

		static changeContent(el, {titleHtml, contentHtml})
		{
			const $el = $(el);
			if ($el.length !== 1 || !$el.is('.popover-toggle')) throw new Error;

			// Popover uninitialized and we don't have to for those common cases
			if (titleHtml !== undefined && $el.attr('data-title-html') !== undefined)
			{
				$el.attr('data-title-html', titleHtml);
				titleHtml = undefined;
			}
			if (contentHtml !== undefined && $el.attr('data-content-html') !== undefined)
			{
				$el.attr('data-content-html', contentHtml);
				contentHtml = undefined;
			}

			if (titleHtml !== undefined || contentHtml !== undefined)
			{
				Popover.init($el).updateHtmlContent({
					title: titleHtml,
					content: contentHtml,
					persistent: true,
					updateSourceType: 'manual',
				});
			}
		}

	}

	return Popover;
});
