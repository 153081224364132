((modifyNette, initNette) => { initNette(modifyNette); })((Nette, Scrolling) => {

	const nonTextTypes = ['button', 'checkbox', 'color', 'file', 'hidden', 'image', 'radio', 'range', 'reset', 'submit'];

	Sim.redefineFunction(Nette, 'getValue', (old, elem) => {
		let val = old();
		if (val && elem && elem.getAttribute)
		{
			if (elem.getAttribute('data-amount-input') !== null)
			{
				val = String(Sim.number.parse(val, null, val));
			}
			else if (elem.getAttribute('data-datepicker-input') !== null)
			{
				val = $(elem).getDatepickerDate(); // null if datepicker is not initialized
				val = val ? parseFloat($.datepicker.formatDate('yymmdd', val)) : NaN;
			}
		}
		return val;
	});

	Sim.redefineFunction(Nette, 'expandRuleArgument', (old, form, arg) => {
		if ($.isPlainObject(arg) && 'date' in arg && 'timezone' in arg && 'timezone_type' in arg) // json_encode(new DateTime)
		{
			const date = $.datepicker.parseDate($.datepicker.W3C, arg.date.substring(0, 10));
			arg = parseFloat($.datepicker.formatDate('yymmdd', date));
		}
		old({2: arg});
	});

	Sim.redefineFunction(Nette.validators, 'range', (old, elem, arg, val) => {
		if (_.isArray(arg))
		{
			arg[0] = arg[0] === undefined ? null : arg[0];
			arg[1] = arg[1] === undefined ? null : arg[1];
		}
		return old({2: arg});
	});

	Sim.redefineFunction(Nette.validators, 'min', (old, elem, arg, val) => {
		return old({2: arg === undefined ? null : arg});
	});

	Sim.redefineFunction(Nette.validators, 'max', (old, elem, arg, val) => {
		return old({2: arg === undefined ? null : arg});
	});

	Nette.initForm = Nette.initOnLoad = (form) => {
		Nette.toggleForm(form);

		if (form.noValidate)
		{
			return;
		}
		form.noValidate = true;

		const submit = (e) => {
			setTimeout(() => (form['nette-submittedBy'] = null), 0); // resets if set from click
			if (!Nette.validateForm(form))
			{
				e.stopPropagation();
				return false;
			}
		};
		const original = form.onsubmit;
		form.onsubmit = typeof original === 'function' ?
			(...args) => (original.apply(form, args) === false ? false : submit.apply(form, args)) :
			submit
		;

		Nette.addEvent(form, 'click', (e) => {
			const sb = $(e.target).closest('input[type="submit"], button[type="submit"], input[type="image"]');
			form['nette-submittedBy'] = sb.length ? sb[0] : null;
		});
	};

	Sim.redefineFunction(Nette, 'addEvent', (old, element, on, callback) => {
		if (element[`netteAddEvent${on}`])
		{
			return;
		}
		element[`netteAddEvent${on}`] = true;
		const isToggleFormHandler = /Nette\.toggleForm/u.test(callback.toString());
		if (isToggleFormHandler)
		{
			callback = (event) => Nette.toggleForm(element.form, event); // fix form but also forward compatible (nette 3.0 uses event instead of elem)
			old({3: callback});
			const inputType = (element.tagName || '').toLowerCase() === 'input' ? element.type.toLowerCase() : null;
			if (on === 'change' && !nonTextTypes.includes(inputType))
			{
				old({2: 'keyup', 3: callback});
				old({2: 'input', 3: callback});
			}
			else if ((on === 'click' || on === 'change') && (inputType === 'checkbox' || inputType === 'radio'))
			{
				old({2: on === 'click' ? 'change' : 'click', 3: callback});
			}
			$(element).on('change', callback); // to trigger for jquery because HTMLInputElement, HTMLSelectElement, HTMLTextAreaElement does not have change event method
		}
		else
		{
			old();
		}
	});

	const toggleElements = (toggleForm, form, srcElement, event) => {
		const original = Nette.toggle;
		Nette.toggle = () => {};
		try { toggleForm(form, event); }
		finally { Nette.toggle = original; }

		const $srcElement = $(srcElement);
		const changedSourceElement = (
			event && srcElement &&
			event.type === 'change' &&
			(!event.isTrigger || (event.delegatedEvent && !event.delegatedEvent.isTrigger)) && // is user action
			$srcElement.is('select, input[type="radio"], input[type="checkbox"]') &&
			($srcElement.is(':focus') || ($srcElement.data('SearchInput') && $srcElement.data('SearchInput').is(':focus')))
		);

		const inputs = new Set;
		for (const [id, visible] of Object.entries(Nette.toggles))
		{
			const elem = document.getElementById(id);
			if (!elem) continue;
			const changed = (elem.style.display !== 'none') !== Boolean(visible);
			elem.style.display = visible ? '' : 'none';
			if (changed && visible && changedSourceElement)
			{
				const $elem = $(elem);
				if ($elem.is(':input'))
				{
					inputs.add(elem);
				}
				for (const el of $elem.find(':input'))
				{
					inputs.add(el);
				}
			}
		}
		if (changedSourceElement)
		{
			const $inputs = $(Array.from(inputs)).filter(':visible').not('[data-toggle-focus-next-disable]');
			if (
				$inputs.length === 1 &&
				$inputs.is('input, textarea') &&
				$inputs.not(nonTextTypes.map((t) => `[type="${t}"]`).join(',')) &&
				Scrolling.isStartMostlyInViewport($inputs[0])
			)
			{
				$inputs.first().trigger('focus');
			}
		}
	};

	Sim.redefineFunction(Nette, 'toggleForm', (old, form, event) => {
		if (!form) // element detached
		{
			return;
		}
		if (!('netteToggleFormThrottle' in form))
		{
			let called;
			const trigger = () => {
				if (called) // trigger on trailing only if called multiple times
				{
					toggleElements(
						(...a) => { old(a); },
						form,
						...(called.size === 1 ? called.entries().next().value : [])
					);
					called = undefined;
				}
			};
			let throttleTimeout;
			const throttle = () => {
				throttleTimeout = undefined;
				trigger();
			};
			form.netteToggleFormThrottle = (e) => {
				// forward compatible with nette 3.0 with event instead of elem
				e = e instanceof window.EventTarget ? new $.Event('NetteToggleForm', {target: e}) : (e ? new $.Event(e, e) : undefined);
				(called || (called = new Map)).set(e ? e.target : undefined, e);
				if (throttleTimeout)
				{
					clearTimeout(throttleTimeout);
				}
				else
				{
					setTimeout(trigger, 0); // leading almost sync
				}
				throttleTimeout = setTimeout(throttle, 100);
			};
		}
		form.netteToggleFormThrottle(event);
	});

}, (modifyNette) => {

	let whenLoaded = (fn) => {
		(whenLoaded.inits || (whenLoaded.inits = new Set)).add(fn);
	};

	Sim.require.defineAsync('Nette', ['libs/netteForms', 'Helpers/Scrolling'], (Nette, ...objs) => {
		const inits = whenLoaded.inits || [];
		whenLoaded = (fn2) => fn2(Nette);
		modifyNette(Nette, ...objs);
		for (const init of inits) init(Nette);
		return Nette;
	});

	Sim.init.addDirectHandler(`[data-nette-rules*='"toggle":{']`, null, (el) => {
		if (el[0].form)
		{
			Sim.require.callback('Nette', (Nette) => Nette.initForm(el[0].form));
		}
	});

	Sim.init.addDirectHandler('form', 'focusin submit', (el) => {
		Sim.require.callback('Nette', (Nette) => Nette.initForm(el[0]));
	});

	Sim.addNetteValidator = (name, validate) => {
		whenLoaded((Nette) => {
			name = name.replace('::', '_').replace(/\\/gu, '');
			if (name in Nette.validators) throw new Error(name);
			Nette.validators[name] = validate;
		});
	};

});
