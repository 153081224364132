Sim.require.amd.registerRaw("/app/Intranet/components/Contracts/ContractDepositDate.js", [], () => {

	class ContractDepositDate
	{

		#form;
		#dateStartInput;
		#options;

		constructor({
			form,
			dateStartSelector = 'input[name="dateStart"]',
			selector = '.js-depositDate',
			depositDateTextSelector = 'span.depositDateText',
			depositDateSwitchSelector = 'input[name$="[useContractStartDate]"]',
			depositDateInputSelector = 'input[name$="[date]"]',
			repeaterSelector = '.js-depositDateInside[data-repeater=""]',
		})
		{
			this.#form = $(form);
			this.#options = {selector, depositDateTextSelector, depositDateSwitchSelector, depositDateInputSelector};

			this.#dateStartInput = this.#form.find(dateStartSelector);
			if (this.#dateStartInput.length !== 1) throw new Error;

			this.#dateStartInput.on('change', () => this.#update());
			this.#form.on('change', depositDateSwitchSelector, (e) => this.#update(e.target));

			Sim.require.callback('app/components/FormRepeater/FormRepeater', () => { // make sure the FormRepeater is initialized
				for (const el of $(repeaterSelector).get())
				{
					$(el).data('repeater').changed.on(() => this.#update(el));
				}
			});
		}

		#update(target = null)
		{
			const date = this.#dateStartInput.getDatepickerDate();
			for (const el of this.#getContainers(target).get())
			{
				const container = $(el);
				const checkbox = container.find(this.#options.depositDateSwitchSelector);
				const text = container.find(this.#options.depositDateTextSelector);
				const input = container.find(this.#options.depositDateInputSelector);

				text.text(date ? Sim.formatDate(date) : text.attr('data-none-date-text'));

				if (checkbox.prop('checked'))
				{
					input.val(date ? Sim.formatDate(date) : '');
				}
			}
		}

		#getContainers(target = null)
		{
			if (target)
			{
				const $target = $(target);
				return $()
					.add($target.find(this.#options.selector))
					.add($target.closest(this.#options.selector))
				;
			}
			return this.#form.find(this.#options.selector);
		}

	}

	return ContractDepositDate;
});
